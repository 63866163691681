import { useEffect, useState } from "react";
import LayoutService from "../Services/LayoutService";

function useHeaderOptionsValue() {
  const [headerOptions, setHeaderOptions] = useState(
    LayoutService.headerOptions$.getValue()
  );

  useEffect(() => {
    const subscription =
      LayoutService.headerOptions$.subscribe(setHeaderOptions);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return headerOptions;
}

export default useHeaderOptionsValue;

export const setHeaderOptionsValue = (value: {
  title: string;
  back: boolean;
}) => {
  LayoutService.headerOptions$.next(value);
};
