import {confirmAlert} from "react-confirm-alert";
import {useHistory} from "react-router";
import {
  resetAuthValue,
} from "src/Modules/AuthModule/Hooks/useAuthValue";
import useDrawerStatusValue, {
  // toggleDrawer,
} from "../../Hooks/useDrawerStatusValue";
import useHeaderOptionsValue from "../../Hooks/useHeaderOptionsValue";

function Header() {
  const drawerOpen = useDrawerStatusValue();
  const {title = "Dashboard", back} = useHeaderOptionsValue();
  const history = useHistory();
  return (
    <section>
      <nav
        id="top-header"
        className={`navbar navbar-expand navbar-light fixed-top my-navbar ${drawerOpen ? "toggled" : ""
          }`}
      >
        <div className="d-flex align-items-center">
          {/* <div
            id="bar"
            className={`nav-icon1 hamburger animated fadeInLeft is-closed ${drawerOpen ? "open" : ""
              }`}
            data-toggle="offcanvas"
            onClick={toggleDrawer}
          >
            <span></span>
            <span></span>
            <span></span>
          </div> */}

          <div>
            <nav aria-label="breadcrumb" className="mb-0">
              <ol className="breadcrumb mb-0 bg-white">
                {back && (
                  <li
                    className="breadcrumb-item"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                      e.stopPropagation();
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      href="/"
                    >
                      Back
                    </a>
                  </li>
                )}
                <li
                  className="breadcrumb-item active text-dark"
                  aria-current="page"
                >
                  {title}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <ul className="navbar-nav ml-auto align-items-center">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle mt-1"
              href="/"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 medium">
                {"Admin"}
              </span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-md dropdown-menu-right py-0 rounded-0"
              aria-labelledby="alertsDropdown"
            >
              <div className="dropdown-menu-header">
                <a
                  className="dropdown-item"
                  href="/profile"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/profile");
                    e.stopPropagation();
                  }}
                >
                  <i className="fa fa-user-circle"></i>Profile
                </a>
                {/* <a
                  className="dropdown-item"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    languageModal.onOpen();
                  }}
                >
                  <i className="fa fa-cog "></i>Languages
                </a> */}
                <a
                  className="dropdown-item "
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    confirmAlert({
                      title: "Logout",
                      message: `Are you sure you want to Logout ?`,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            resetAuthValue();
                            history.push('/');
                          },
                        },
                        {
                          label: "No",
                          onClick: () => {},
                        },
                      ],
                    });
                  }}
                >
                  <i className="fa fa-sign-out"></i>Logout
                </a>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default Header;
