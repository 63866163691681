import {useQuery} from "react-query";
import TagService from "../Service/TagService";

function useTenantsQuery(params: any) {
  const {data, isLoading, refetch} = useQuery(
    TagService.queryKeys.tenants,
    () => TagService.getTanants(params),
  );
  const tenants = data?.data?.tenants ?? [];

  return {
    tenants,
    isLoading,
    refetch
  };
}

export default useTenantsQuery;
