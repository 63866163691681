import Spinner from "react-spinner-material";

function ListLoader(props: {
  loading: boolean;
  dataLength: number;
  noDataText?: string;
}) {
  const { loading, dataLength, noDataText = "No Data Found" } = props;
  if (!loading && dataLength > 0) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        marginTop: 230,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      {loading && (
        <Spinner radius={60} color={"#76a400"} stroke={5} visible={true} />
      )}
      {!loading && dataLength === 0 && <p>{noDataText}</p>}
    </div>
  );
}

export default ListLoader;
