import {QueryClientProvider} from "react-query";
import {queryClient} from "./Utils/ReactQueryConfig";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./Navigation/AppRouter";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
