import {useForm} from "react-hook-form";
import FormInput from "src/Components/FormInput/FormInput";
import useModal from "src/Hooks/useModal";
import {
  getRequiredRules,
} from "src/Utils/Validators";
import useAddTagMutation from "../../Hooks/useAddTagMutation";
import useEditTagMutation from "../../Hooks/useEditTagMutation";
import {useRef, useState} from "react";

type TagModalProps = {
  tenantId: string;
  visible: boolean;
  tenants: Array<any>;
  editFormData?: any;
  onClose: () => void;
  onSubmit?: () => void;
};

function TagModal(props: TagModalProps) {
  const {visible, onClose, editFormData, tenants, tenantId} = props;
  const modalID = "tag-modal";
  const {control, handleSubmit} = useForm({
    defaultValues: {
      name: editFormData?.name ?? "",
      description: editFormData?.description ?? "",
      tenant_id: editFormData?.tenant_id ?? tenantId,
    },
    mode: "onChange",
  });
  const uploadScriptRef = useRef<any>();
  const [selectedScript, setSelectedScript] = useState<any>(
    editFormData?.script_file_name ?? null
  );
  const [script, setScriptFile] = useState<File | undefined>(
    undefined
  );
  const addTagMutation = useAddTagMutation();
  const editTagMutation = useEditTagMutation();

  const showModal = useModal(modalID, visible, () => {});
  const onApply = handleSubmit((values) => {
    if (editFormData) {
      editTagMutation.mutate(
        {
          ...values,
          script,
          tag_id: editFormData?.id,
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    } else {
      addTagMutation.mutate({...values, script}, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  });
  const handleApply = (e: any) => {
    e.preventDefault();
    onApply();
  };

  return (
    <>
      <div
        className={`modal fade`}
        id={modalID}
        style={{
          display: showModal ? "block" : "none",
          paddingLeft: 0,
        }}
        onClick={onClose}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title fz18 semibold_font">
                {editFormData ? "Edit" : "Add"} Tag
              </h4>
              <div onClick={onClose} className="cursor-pointer">
                <span><i className="fa fa-times" /></span>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-row">
                  <FormInput
                    className={"form-group col-md-12"}
                    name={"tenant_id"}
                    control={control}
                    label={"Tenant"}
                    type="select"
                    inputProps={{
                      showEmptyOption: false,
                      disabled: editFormData ? true : false
                    }}
                    options={tenants}
                  />
                  <FormInput
                    className={"form-group col-md-12"}
                    inputProps={{
                      disabled: editFormData ? true : false
                    }}
                    name={"name"}
                    control={control}
                    label={"Tag"}
                  />
                  <FormInput
                    className={"form-group col-md-12"}
                    label={"Description"}
                    name={"description"}
                    control={control}
                    rules={getRequiredRules("Description")}
                  />
                  <div className="form-group col-md-12">
                    <label className="gray_light fz15 regular_font">
                      Script File
                    </label>
                    <div className="d-flex align-items-center justify-content-between">
                      <p
                        className="mb-0 fz15"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        {selectedScript && (
                          <div>{selectedScript}</div>
                        )}
                      </p>
                      <label
                        onClick={() => {
                          uploadScriptRef.current?.click();
                        }}
                      >
                        <p className="btn custom_btn rounded mb-0 bg-white blue_color">
                          Upload
                        </p>
                      </label>
                      <input
                        ref={uploadScriptRef}
                        type="file"
                        name="tag_script"
                        className="d-none"
                        id="upload_img1"
                        accept=".js"
                        onChange={(event) => {
                          if (event.target.files) {
                            const file = event?.target?.files[0];
                            setScriptFile(file);
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              setSelectedScript(e?.target?.result);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <button
                      disabled={addTagMutation.isLoading || editTagMutation.isLoading}
                      onClick={handleApply}
                      className="btn custom_btn rounded w-100"
                    >
                      {addTagMutation.isLoading || editTagMutation.isLoading
                        ? "Submitting..."
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
}

export default TagModal;
