import { useEffect } from "react";
import { setHeaderOptionsValue } from "./useHeaderOptionsValue";

function useSetHeaderOptions(props: { title: string; back?: boolean }) {
  const { title, back = false } = props;

  useEffect(() => {
    setHeaderOptionsValue({ title, back });
  }, [back, title]);
}

export default useSetHeaderOptions;
