import {AxiosResponse, AxiosError} from "axios";
import {useMutation} from "react-query";
import {onSuccess, onError} from "src/Utils/Helpers";
import {queryClient} from "src/Utils/ReactQueryConfig";
import TagService from "../Service/TagService";

function useTagStatusMutation() {
  return useMutation(
    TagService.tagStatus,
    {
      onSuccess: (
        _response: AxiosResponse<any>
      ) => {
        queryClient.refetchQueries();
        onSuccess(_response);
      },
      onError: (_error: AxiosError<any>) => onError(_error),
    }
  );
}

export default useTagStatusMutation;
