import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import "./Assets/CSS/index.css";
import "./Assets/CSS/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Assets/CSS/perfect-scrollbar.css";
import "./Assets/CSS/font-awesome.min.css";
import "./Assets/Fonts/poppin-font/stylesheet.css";
import "./Assets/CSS/main-style.css";
import "./Assets/CSS/stylesheet.css";
import "./Assets/CSS/select2.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import App from './App';

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
