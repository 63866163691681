import {BehaviorSubject} from "rxjs";
import {fetcher} from "src/Utils/Helpers";
import PersistStorage from "src/Utils/PersistStorage";
import {AUTH_STATE} from "../Types/CommonTypes";
import {getAuthValue} from "../Hooks/useAuthValue";
import {LOGIN_REQUEST} from "../Types/RequestTypes";

class AuthService {
  queryKeys = {};
  authState$;
  initialAuthState: AUTH_STATE = {
    language: "en",
    loggedIn: false,
    token: "",
  };
  registerParams: any = {};

  constructor() {
    this.authState$ = new BehaviorSubject<AUTH_STATE>(this.initialAuthState);

    const persistStorage = new PersistStorage("authState", this.authState$);
    persistStorage.init();
  }

  resetAuthValue = () => {
    const {language} = getAuthValue();
    this.authState$.next({...this.initialAuthState, language});
  };

  login = (data: LOGIN_REQUEST) => {
    return fetcher({
      url: "/login",
      method: "POST",
      data,
    });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
