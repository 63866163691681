import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import useAuthValue from "src/Modules/AuthModule/Hooks/useAuthValue";
import MasterLayout from "src/Modules/LayoutModule/Components/MasterLayout/MasterLayout";

export type ProtectedRouteProps = {
  authenticationPath?: string;
} & RouteProps;

function ProtectedRoute(props: ProtectedRouteProps) {
  const { authenticationPath = "/login", ...routeProps } = props;
  const { loggedIn } = useAuthValue();

  if (loggedIn) {
    return (
      <MasterLayout>
        <Route {...routeProps} />
      </MasterLayout>
    );
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}

export default ProtectedRoute;
