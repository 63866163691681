import {BehaviorSubject} from "rxjs";

class LayoutService {
  drawerOpen$ = new BehaviorSubject(true);
  headerOptions$ = new BehaviorSubject({
    title: "",
    back: false,
  });
  queryKeys = {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LayoutService();
