import React from "react";
import useDrawerStatusValue from "../../Hooks/useDrawerStatusValue";

function Content(props: { children: any }) {
  const { children } = props;
  const drawerOpen = useDrawerStatusValue();

  return (
    <section>
      <div
        id="page-content-wrapper"
        className={`mt-65 pharmacy-section ${drawerOpen ? "toggled" : ""}`}
      >
        <div id="content">{children}</div>
      </div>
    </section>
  );
}

export default Content;
