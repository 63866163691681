import React, {useState} from "react";
import ListLoader from "src/Modules/LayoutModule/Components/ListLoader/ListLoader";
import usePopup from "src/Hooks/usePopup";
import TagModal from "../TagModal/TagModal";
import useTenantsQuery from "../../Hooks/useTenantsQuery";
import useTagsQuery from "../../Hooks/useTagsQuery";
import FormInput from "src/Components/FormInput/FormInput";
import {useForm} from "react-hook-form";
import useTagStatusMutation from "../../Hooks/useTagStatusMutation";
import useDeleteTagMutation from "../../Hooks/useDeleteTagMutation";
import {confirmAlert} from "react-confirm-alert";

function TagList() {
  const tagModal = usePopup();
  const [editFormData, setFormData] = useState(undefined);
  const {tenants = []} = useTenantsQuery({})
  const {control, watch} = useForm({
    defaultValues: {
      tenantId: "",
    },
    mode: "onChange",
  });
  const {tags = [], refetch} = useTagsQuery(watch("tenantId"));
  const {mutate: changeStatus} = useTagStatusMutation();
  const {mutate: deleteTag} = useDeleteTagMutation()
  return (
    <div className="container-fluid p-0 px-lg-0 px-md-0">
      <div className="container-fluid px-lg-4">
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="card">
              <div className="card-body">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="d-sm-flex align-items-center" style={{flex: 0.5}}>
                    <h2 className="h3 mb-3 mb-sm-0 semibold_font fz18">
                      Tags List
                    </h2>
                    <FormInput
                      className={"form-group col-md-6 ml-2 mb-0"}
                      name={"tenantId"}
                      control={control}
                      label={"Tenant"}
                      type="select"
                      inputProps={{
                        showSelectOption: true,
                        showLabel: false,
                      }}
                      options={tenants}
                    />
                  </div>
                  <div className="d-sm-flex align-items-center">
                    <button
                      className="btn custom_btn shadow-sm fz10 d-flex align-items-center mt-3 mt-sm-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setFormData(undefined);
                        tagModal.onOpen();
                        e.stopPropagation();
                      }}
                    >
                      <i
                        className="fa fa-plus mx-2 fz14"
                        aria-hidden="true"
                      ></i>
                      Add New
                    </button>
                  </div>
                </div>
              </div>
              <div id="main" className="mt-0 mb-3">
                <div className="p-3">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tag Name</th>
                          <th>Script Path</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tags.map((tag: any) => (
                          <tr>
                            <td className="border_bottom">
                              {tag.name}
                            </td>
                            <td className="border_bottom">
                              {tag.script_file_name}
                            </td>
                            <td className="border_bottom">
                              <div className="d-flex align-items-center">
                                <div className="custom_switch mx-4">
                                  <label
                                    className={`switch ${tag.is_active ? "active" : "inactive"
                                      } mb-0`}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={tag.is_active}
                                      onChange={() => {
                                        changeStatus({
                                          tagId: tag.id,
                                          is_active: !tag.is_active
                                        }, {
                                          onSuccess: () => refetch()
                                        })
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td className="border_bottom">
                              <div className="d-flex align-items-center">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFormData(tag);
                                    tagModal.onOpen();
                                    e.stopPropagation();
                                  }}
                                  className="btn btn_primary"
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    confirmAlert({
                                      title: "Delete Tag",
                                      message: `Are you sure you want to Delete ?`,
                                      buttons: [
                                        {
                                          label: "Yes",
                                          onClick: () => {
                                            deleteTag({tagId: tag.id})
                                          },
                                        },
                                        {
                                          label: "No",
                                          onClick: () => {},
                                        },
                                      ],
                                    });
                                    e.stopPropagation();
                                  }}
                                  className="btn btn_delete"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4}>
                            <ListLoader
                              loading={false}
                              dataLength={
                                (tags).length
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tagModal.open && <TagModal
        tenantId={watch('tenantId')}
        tenants={tenants}
        editFormData={editFormData}
        visible={tagModal.open}
        onClose={() => {
          tagModal.onClose();
        }}
      />
      }
    </div>
  );
}
export default TagList;
