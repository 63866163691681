import React from "react";
import "./Login.css";
import useLoginMutation from "src/Modules/AuthModule/Hooks/useLoginMutation";
import {useForm} from "react-hook-form";
import FormInput from "src/Components/FormInput/FormInput";
import {getRequiredRules} from "src/Utils/Validators";

function LoginPage() {
  const {login, isLoading} = useLoginMutation();
  const {control, handleSubmit} = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card p-3">
              <div className="card-body">
                <div className="text-center">
                  <h4 className="fz20 bold_font mb-4">Login to CDP</h4>
                </div>
                <form
                  onSubmit={handleSubmit((values) => {
                    login(values);
                  })}
                >
                  <FormInput
                    className="form-group"
                    name={"email"}
                    control={control}
                    label={""}
                    rules={getRequiredRules("Email")}
                    inputProps={{
                      placeholder: "Email",
                      className: "form-control",
                    }}
                  />
                  <FormInput
                    type={"password"}
                    className="form-group"
                    name={"password"}
                    control={control}
                    label={""}
                    rules={getRequiredRules("Password")}
                    inputProps={{
                      placeholder: "Password",
                      className: "form-control",
                    }}
                  />
                  <div className="form-group login-submit">
                    <button
                      className="btn btn-primary btn-xl w-100 py-2"
                      type={"submit"}
                      onSubmit={handleSubmit((values) => {
                        login(values);
                      })}
                      disabled={isLoading}
                    >
                      {isLoading ? "Signing in..." : "Sign me in"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
