import React from "react";
import Content from "../Content/Content";
import Header from "../Header/Header";

function MasterLayout(props: {children: any}) {
  const {children} = props;

  return (
    <div id="wrapper">
      <div className="overlay"></div>
      <Header />
      <Content>{children}</Content>
    </div>
  );
}

export default MasterLayout;
