import { Redirect, Route, RouteProps } from "react-router";
import useAuthValue from "src/Modules/AuthModule/Hooks/useAuthValue";

export type AuthRouteProps = {
  authenticationPath?: string;
} & RouteProps;

function AuthRoute(props: AuthRouteProps) {
  const { authenticationPath = "/", ...routeProps } = props;
  const { loggedIn } = useAuthValue();

  if (!loggedIn) {
    return <Route {...routeProps} />;
  } else if (loggedIn && authenticationPath === "/") {
    return <Redirect to={{ pathname: "/tags" }} />;
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}

export default AuthRoute;
