import React from 'react';
import useSetHeaderOptions from 'src/Modules/LayoutModule/Hooks/useSetHeaderOptions';
import TagList from 'src/Modules/TagModule/Components/TagList/TagList';

const TagsPage = () => {
  useSetHeaderOptions({title: "Tags List"});

  return <TagList />;
}
export default TagsPage;
