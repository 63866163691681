import useObservableValue from "src/Hooks/useObservable";
import LayoutService from "../Services/LayoutService";

function useDrawerStatusValue(): boolean {
  return useObservableValue(LayoutService.drawerOpen$);
}

export default useDrawerStatusValue;

export const toggleDrawer = () =>
  LayoutService.drawerOpen$.next(!LayoutService.drawerOpen$.getValue());
