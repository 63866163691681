import {AxiosResponse} from "axios";
import {useMutation} from "react-query";
import {useHistory} from "react-router";
import {onError} from "src/Utils/Helpers";
import AuthService from "../Services/AuthService";
import {LOGIN_RESPONSE} from "../Types/ResponseTypes";
import {getAuthValue, setAuthValue} from "./useAuthValue";
import SnackbarHandler from "src/Utils/SnackbarHandler";

function useLoginMutation() {
  const history = useHistory();
  const loginMutation = useMutation(AuthService.login, {
    onSuccess: (responseData) => {
      const {
        status,
        data,
      }: AxiosResponse<LOGIN_RESPONSE> = responseData;
      if (status === 200) {
        const { token, message } = data as LOGIN_RESPONSE;
        SnackbarHandler.successToast(message);
        setAuthValue({
          ...getAuthValue(),
          loggedIn: true,
          token,
        });
        history.push({ pathname: "/tags" });
      }
    },
    onError: onError,
  });

  return {
    login: loginMutation.mutate,
    isLoading: loginMutation.isLoading,
  };
}

export default useLoginMutation;
