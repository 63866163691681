import {useQuery} from "react-query";
import TagService from "../Service/TagService";

function useTagsQuery(tenantId: any) {
  const {data, isLoading, refetch} = useQuery(
    TagService.queryKeys.tags(tenantId),
    () => TagService.getTags({tenantId}),
    {enabled: tenantId !== ""}
  );
  const tags = data?.data?.tags ?? [];

  return {
    tags,
    isLoading,
    refetch
  };
}

export default useTagsQuery;
